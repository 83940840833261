#HelpOpenCon1Main {
  z-index: 1100;
}

.HelpSectionCon2 {
  background: linear-gradient(#3a7d44 0%, #3a7d441a 100%);
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  padding: 6px 12px;
  display: flex;
}

.HelpSectionCon3 {
  color: var(--app-white, #fff);
  background: linear-gradient(270deg, #3a7d44 0%, #3a7d4400 100%);
  border-radius: 5px;
  align-items: center;
  gap: 12px;
  padding: 3px 6px;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
}

.HelpSectionText1 {
  color: var(--app-white, #fff);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.HelpSectionCon4 {
  align-items: center;
  gap: 6px;
  display: flex;
}

.HelpSectionText2 {
  color: var(--app-white, #fff);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.HelpSectionCon5 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  height: 100%;
  padding: 24px 12px;
  display: flex;
  overflow: hidden scroll;
}

.HelpSectionCon6 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  display: flex;
}

.HelpSectionCon7 {
  background: var(--app-white, #fff);
  border-radius: 6px;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  padding: 6px;
  display: flex;
  box-shadow: 0 1px 4px 2px #00000040;
}

.HelpSectionText3 {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.HelpSectionSearchInput {
  width: 100%;
  padding: 2px 6px;
}

.HelpSectionCon8 {
  flex-wrap: wrap;
  align-content: flex-start;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  padding: 0 6px;
  display: flex;
}

.HelpSectionCon9 {
  background: var(--MAI-linear, linear-gradient(0deg, #03c11f 0%, #fffb00 100%));
  border-radius: 3px;
  align-items: center;
  gap: 10px;
  padding: 3px 6px;
  display: flex;
  box-shadow: inset 0 0 2px 1px #00000040;
}

.HelpSectionText4 {
  color: var(--app-green, #3a7d44);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.HelpSectionCon10 {
  box-sizing: border-box;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: 100%;
  padding: 12px 0 68px;
  display: flex;
  overflow: hidden scroll;
}

.HelpSectionChatButtonCon1 {
  background: var(--Mercado-MAI-pro, linear-gradient(0deg, #f80 0%, #e2ff04 100%));
  z-index: 1;
  border-radius: 12px 0 0 12px;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 6px 12px;
  display: flex;
  position: absolute;
  bottom: 32px;
  right: 0;
  box-shadow: 0 4px 4px #00000040, inset 0 0 6px #00000040;
}

.HelpSectionText5 {
  color: var(--app-white, #fff);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.HelpSectionVideoCon1 {
  background: var(--MAI-linear, linear-gradient(0deg, #03c11f 0%, #fffb00 100%));
  border-radius: 6px;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
  scale: 1;
  box-shadow: inset 0 0 4px 1px #00000040;
}

.HelpSectionVideoCon1Active {
  border: solid 2px var(--app-green);
  scale: .97;
}

.HelpSectionVideoCon2 {
  align-self: stretch;
  align-items: center;
  gap: 10px;
  height: 220px;
  padding: 6px;
  display: flex;
}

.HelpSectionVideoCon3 {
  flex: 1 0 0;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.HelpSectionVideoCon3:after {
  content: "";
  background: #0003;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.HelpSectionVideoThumbnailCon {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.HelpSectionVideoPLayVideoIcon {
  z-index: 1;
  position: absolute;
}

.HelpSectionVideoCon4 {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 6px;
  padding: 6px;
  display: flex;
}

.HelpSectionVideoText1 {
  color: #000;
  align-self: stretch;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.HelpSectionVideoCon5 {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 2px;
  padding: 0 6px;
  display: flex;
}

.HelpSectionVideoText2 {
  color: var(--app-white, #fff);
  flex: 1 0 0;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.HelpSectionVideoText2B {
  color: var(--app-gray-medium, #6d6d6d);
  align-self: stretch;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

#MainContainerForVideoDisplayer {
  z-index: 1100;
}
/*# sourceMappingURL=index.3a22e0ca.css.map */
