#HelpOpenCon1Main {
  z-index: 1100;
}
.HelpSectionCon2 {
  display: flex;
  padding: 6px 12px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  background: linear-gradient(180deg, #3a7d44 0%, rgba(58, 125, 68, 0.1) 100%);
}
.HelpSectionCon3 {
  display: flex;
  padding: 3px 6px;
  align-items: center;
  gap: 12px;

  border-radius: 5px;
  background: linear-gradient(270deg, #3a7d44 0%, rgba(58, 125, 68, 0) 100%);

  color: var(--app-white, #fff);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.HelpSectionText1 {
  color: var(--app-white, #fff);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.HelpSectionCon4 {
  display: flex;
  align-items: center;
  gap: 6px;
}
.HelpSectionText2 {
  color: var(--app-white, #fff);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.HelpSectionCon5 {
  display: flex;
  padding: 24px 12px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
.HelpSectionCon6 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.HelpSectionCon7 {
  display: flex;
  padding: 6px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 6px;
  background: var(--app-white, #fff);
  box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.25);
}
.HelpSectionText3 {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.HelpSectionSearchInput {
  width: 100%;
  padding: 2px 6px;
}
.HelpSectionCon8 {
  display: flex;
  padding: 0px 6px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 12px;
  align-self: stretch;
  flex-wrap: wrap;
}
.HelpSectionCon9 {
  display: flex;
  padding: 3px 6px;
  align-items: center;
  gap: 10px;
  border-radius: 3px;
  background: var(
    --MAI-linear,
    linear-gradient(0deg, #03c11f 0%, #fffb00 100%)
  );
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25) inset;
}
.HelpSectionText4 {
  color: var(--app-green, #3a7d44);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.HelpSectionCon10 {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  overflow: hidden;
  overflow-y: scroll;
  padding: 12px 0px 68px 0px;
  box-sizing: border-box;
}

.HelpSectionChatButtonCon1 {
  position: absolute;
  bottom: 32px;
  right: 0px;
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 24px;
  border-radius: 12px 0px 0px 12px;
  background: var(
    --Mercado-MAI-pro,
    linear-gradient(0deg, #f80 0%, #e2ff04 100%)
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 0px 6px 0px rgba(0, 0, 0, 0.25) inset;
  z-index: 1;
}
.HelpSectionText5 {
  color: var(--app-white, #fff);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.HelpSectionVideoCon1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 6px;
  background: var(
    --MAI-linear,
    linear-gradient(0deg, #03c11f 0%, #fffb00 100%)
  );
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25) inset;
  scale: 1;
}
.HelpSectionVideoCon1Active {
  scale: 0.97;
  border: solid 2px var(--app-green);
}
.HelpSectionVideoCon2 {
  display: flex;
  height: 220px;
  padding: 6px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
/* .HelpSectionVideoCon3 {
  position: relative;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.2) 100%
  );
  overflow: hidden;
} */

.HelpSectionVideoCon3 {
  position: relative; /* Needed for absolute positioning of overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  overflow: hidden;
}

.HelpSectionVideoCon3::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2); /* Semi-transparent black overlay */
}

/* .HelpSectionVideoThumbnailCon {
  width: 100%;
} */
.HelpSectionVideoThumbnailCon {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center; /* Change to 'top', 'bottom', 'left', or 'right' if needed */
}

.HelpSectionVideoPLayVideoIcon {
  position: absolute;
  z-index: 1;
}
.HelpSectionVideoCon4 {
  display: flex;
  padding: 6px;
  gap: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}
.HelpSectionVideoText1 {
  align-self: stretch;
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.HelpSectionVideoCon5 {
  display: flex;
  padding: 0px 6px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
}
.HelpSectionVideoText2 {
  flex: 1 0 0;
  color: var(--app-white, #fff);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.HelpSectionVideoText2B {
  align-self: stretch;
  color: var(--app-gray-medium, #6d6d6d);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
#MainContainerForVideoDisplayer {
  z-index: 1100;
}
